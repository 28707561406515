import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Amplify from "@aws-amplify/core";
import aws_exports from "./aws-exports";
import { StoreProvider } from "./context/StoreContext";

// config for Amplify
Amplify.configure(aws_exports);
// set default logger level
Amplify.Logger.LOG_LEVEL = "DEBUG";

ReactDOM.render(
  <StoreProvider>
    <Router>
      <App />
    </Router>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
