// *******************************************************
// Service module to handle error logging functionality //
// *******************************************************

import { Logger } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { createSystemErrorLog } from "../graphql/mutations";
import moment from "moment";

const logger = new Logger("SystemError");

const getCurrentDate = () => {
  return moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};

async function logSystemError(companyId, severity, source, line, error) {
  const input = {
    companyId,
    severity,
    source,
    // error.lineNumber this does not work
    line: error.stack,
    //stack: error.stack,
    error: error.message,
    createdAt: getCurrentDate(),
  };

  const result = await API.graphql(
    graphqlOperation(createSystemErrorLog, { input })
  );

  // logger.debug("In logSystemError, result = " + JSON.stringify(result));
}

export { logSystemError };
