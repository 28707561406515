// custom listBookingsQuery
// needed to add second level of nesting for user.lastname, user.firstname
export const listBookingsQuery = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        desc
        order {
          id
          desc
          orderNo
          companyId
          clientId
          providerId
          amount
          status
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
        }
        provider {
          id
          firstname
          lastname
          emailaddress
        }
        providerId
        client {
          id
          userId
          user {
            firstname
            lastname
          }
        }
        clientId
        startdate
        minutes
        company {
          id
          name
          emailaddress
        }
        companyId
        status
        timeblockid
        cancelledAt
        cancelledBy {
          id
          username
          emailaddress
        }
        noshowAt
        noshowBy {
          id
          username
          emailaddress
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const servicesOfCompany = /* GraphQL */ `
  query ServiceTypeByCompany(
    $companyId: String
    $categoryNameName: ModelServiceTypeByCompanySortedByCategoryNameNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceTypeByCompany(
      companyId: $companyId
      categoryNameName: $categoryNameName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        desc
        categoryId
        categoryName
        category {
          id
          name
          ordinal
        }
        locations
        minutes
        price
        active
        taxexempt
        TaxOverride
        ordinal
        deleted
        companyId
        behavior
      }
      nextToken
    }
  }
`;

export const skillByCompany = /* GraphQL */ `
  query SkillByCompany(
    $companyId: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillByCompany(
      companyId: $companyId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        importance
        companyId
        active
        deleted
      }
      nextToken
    }
  }
`;

export const bundledServicesByCompany = /* GraphQL */ `
  query BundledServicesByCompany(
    $companyId: String
    $includedServices: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bundledServicesByCompany(
      companyId: $companyId
      includedServices: $includedServices
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        desc
        categoryId
        categoryName
        category {
          id
          name
        }
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        TaxOverride
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const regionalPricingByCompanyServiceType = /* GraphQL */ `
  query RegionalPricingByCompanyServiceType(
    $companyId: String
    $servicetypeId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegionalPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regionalPricingByCompanyServiceType(
      companyId: $companyId
      servicetypeId: $servicetypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        servicetypeId
        pricingtype
        country
        countryName
        province
        postalcodes
        price
        notes
        companyId
        active
        deleted
        createdAt
        updatedAt
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
        }
      }
      nextToken
    }
  }
`;

export const packageByCompany = /* GraphQL */ `
  query PackageByCompany(
    $companyId: String
    $servicetypeIdCreatedAt: ModelPackageByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packageByCompany(
      companyId: $companyId
      servicetypeIdCreatedAt: $servicetypeIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
