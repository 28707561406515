const typography = {
  fontFamily: ["Raleway", "Roboto", "sans-serif"].join(","),
  h1: {
    fontSize: "3.75rem",
    fontWeight: "900",
  },
  h2: {
    fontSize: "2.25rem",
    fontWeight: "900",
    color: "rgba(0,0,0,0.75)",
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: "700",
    color: "rgba(0,0,0,0.75)",
  },
  h4: {
    fontSize: "1.13rem",
    fontWeight: "700",
    fontFamily: ["Roboto", "sans-serif"].join(","),
    color: "rgba(0,0,0,0.75)",
  },
  h5: {
    fontSize: "1rem",
    fontWeight: "700",
    fontFamily: ["Roboto", "sans-serif"].join(","),
    color: "rgba(0,0,0,0.75)",
  },
  h6: {
    fontSize: "0.88rem",
    fontWeight: "700",
    fontFamily: ["Roboto", "sans-serif"].join(","),
    color: "rgba(0,0,0,0.75)",
  },
  body1: {
    fontSize: "1rem",
    fontWeight: "400",
    fontFamily: ["Roboto"],
  },
  body2: {
    fontSize: "0.88rem",
    fontWeight: "400",
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  caption: {
    fontSize: "0.5rem",
    fontWeight: "300",
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  button: {
    fontSize: "1rem",
    fontWeight: "700",
    fontFamily: ["Roboto", "sans-serif"].join(","),
    textTransform: "capitalize",
    letterSpacing: "1px",
  },
  subtitle1: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  subtitle2: {
    fontSize: "0.75rem",
    fontWeight: "400",
    color: "rgba(0,0,0,0.5)",
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  overline: {
    fontSize: "0.88rem",
    fontWeight: "400",
    color: "rgba(0,0,0,0.5)",
    textTransform: "uppercase",
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  helperText: {
    fontSize: "0.75rem",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.35)",
    fontStyle: "italic",
    fontFamily: ["Roboto"],
  },
};

export default typography;
