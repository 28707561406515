// ************************************
// Service module to handle messaging common functionality //
// ************************************

import API, { graphqlOperation } from "@aws-amplify/api";

async function sendSms(to, msg, countryCode) {
  let from = await getSenderNumberByCountry(countryCode);
  const result = await API.post("messaging", "/send", {
    body: {
      from,
      to,
      msg,
    },
  });
  console.log(JSON.stringify(result));
}

async function getSenderNumberByCountry(countryCode) {
  const getRefData = /* GraphQL */ `
    query GetRefData($refType: ID!, $refName: String!) {
      getRefData(refType: $refType, refName: $refName) {
        refType
        refName
        refValue
      }
    }
  `;
  let resp = await API.graphql(
    graphqlOperation(getRefData, {
      refType: "TWILIO_FROM_NUM",
      refName: countryCode,
    })
  );
  if (resp.data.getRefData) return resp.data.getRefData.refValue;
  else {
    resp = await API.graphql(
      graphqlOperation(getRefData, {
        refType: "TWILIO_FROM_NUM",
        refName: "DEFAULT",
      })
    );
    if (resp.data.getRefData) return resp.data.getRefData.refValue;
  }
  return process.env.REACT_APP_TWILIO_SMS_SEND_NUMBER;
}

export { sendSms, getSenderNumberByCountry };
