/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_mobile_analytics_app_id: "4f2dae4511374f85aebf5a928d41cb39",
  aws_mobile_analytics_app_region: "us-east-1",
  aws_cloud_logic_custom: [
    {
      name: "adminapi",
      endpoint: "https://qm7otfoc67.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "auditapi",
      endpoint: "https://ethi6ivcw1.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "bookingapi",
      endpoint: "https://1smwa7vzb6.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "changeuserrole",
      endpoint: "https://1x37dp8hqd.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "hubspotapi",
      endpoint: "https://o62trj46ih.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "integrationapi",
      endpoint: "https://jfu8sjc0c5.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "mapdirection",
      endpoint: "https://v3dfgb6fp4.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "messaging",
      endpoint: "https://ufhierrkwh.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "promoapi",
      endpoint: "https://7st8zf98gj.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "recurringBookingsApi",
      endpoint: "https://rirdgzjh6i.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "redirect",
      endpoint: "https://xb7s6qcmx2.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "s3filemgmt",
      endpoint: "https://uhuus84d66.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "searchapi",
      endpoint: "https://js178mamsd.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "sendsesemail",
      endpoint: "https://yzlp0y80rh.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "sendtwilioemail",
      endpoint: "https://xm4gc5kc8e.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "stripechargecard",
      endpoint: "https://91klqw04va.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "stripeconnect",
      endpoint: "https://b49x4eyrnh.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "stripesavecard",
      endpoint: "https://77cy1lvn80.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "twiliosmsin",
      endpoint: "https://wxtgk9xwp9.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
    {
      name: "virtualmeeting",
      endpoint: "https://at05ot2e63.execute-api.us-east-1.amazonaws.com/prodaa",
      region: "us-east-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://wk56cxiyizbj7lnn24i6out7h4.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_identity_pool_id:
    "us-east-1:2c370eb6-4e0d-4a42-ba10-f02217502424",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_gQhrm0cU8",
  aws_user_pools_web_client_id: "4qjalmqfpfpga2a8na0qupvop1",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_content_delivery_bucket: "marketbox-20190726012602-hostingbucket-prodaa",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_url:
    "http://marketbox-20190726012602-hostingbucket-prodaa.s3-website-us-east-1.amazonaws.com",
  aws_user_files_s3_bucket: "marketboxproviderpics-prodaa",
  aws_user_files_s3_bucket_region: "us-east-1",
};

export default awsmobile;
