import API, { graphqlOperation } from "@aws-amplify/api";
import moment from "moment";
import {
  execRead,
  execWrite,
  execReadBySortkey,
  execReadByPK,
} from "./DBService";
import * as scheduleQueries from "./ScheduleQueries";
import * as queries from "../graphql/queries";
import { utcToZonedTime, zonedTimeToUtc, format, toDate } from "date-fns-tz";
import { createProviderSchedule } from "../graphql/mutations";
import { v4 as uuid } from "uuid";
import { stripLocationPrefix } from "../utils";
import { addDays } from "date-fns";
import { uniq } from "lodash";
import { mod } from "../utils";
//TODO: Timezone has to be Location timezone or defaule timezone at company level
//TODO: Move date functions to its own DateUtil module
//The dates passed into the ScheduleService will be considered to be Location Timezone or Company Timezone
const SLOTSINADAY = 288;
const SLOTUNITMINS = 5;
const SLOTSINHOUR = 12;
const TIMEMAP = buildTimeMap();
const SCHEDPKSKSPLITAT = "::";
const NOTRAVELTIMERADIUS = 1000; //in meters
const EARTHRADIUS = 6371e3; //in meters
const ALLDAYSARRAY = [0, 1, 2, 3, 4, 5, 6];
export const DEFAULT_LOOKAHEAD_DAYS_FOR_CLUSTERING = 7;
const DEFAULT_MAX_NUM_OF_SUGGESTED_SLOTS_TO_DISPALY = 10;

function weekdaysString(wdArray) {
  return "" + wdArray.join("");
}
// use API
const createTimeblock = async ({
  companyId,
  locationId,
  startDate,
  endDate,
  startTime,
  endTime,
  type,
  status,
  weeksToRepeat,
  weekDays,
  tz,
  sdtutc,
  providerId,
  geoLoc,
}) => {
  const weekdaysStr = weekDays ? weekdaysString(weekDays) : weekDays;

  const timeblockData = {
    id: getProviderScheduleId(companyId, providerId),
    scheduleinfo: `BK|${startDate} ${startTime}|${locationId}|${uuid()}`,
    companyId,
    startDate,
    endDate,
    startTime,
    endTime,
    type,
    status,
    tz,
    active: true,
    providerId,
    providerScheduleProviderId: providerId,
    ...(weekdaysStr && { weekDays: weekdaysStr }),
    ...(weeksToRepeat && { weeksToRepeat }),
    sdtutc,
    locations: JSON.stringify([`${locationId}`]),
    ...(geoLoc?.lat && { latitude: geoLoc.lat }),
    ...(geoLoc?.lng && { longitude: geoLoc.lng }),
  };
  let response = await execWrite({
    opname: "createProviderSchedule",
    op: createProviderSchedule,
    input: timeblockData,
  });
  if (response) {
    response = {
      ...response,
      id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`,
    };
  }
  return response;
};
const getSlotsForSbs = async ({
  SBs,
  startDate, // TODO: change the format to regular date from aws date
  numberOfDays,
  bookingIntervalMinutes,
  serviceDuration,
  travelTime,
  geoLoc,
  bookingIncrement,
  providerTz,
  locationTz,
  companyId,
  providerId,
  addStats = false,
  returnUnreadable = false,
}) => {
  const timeZoneAdjustedScheduleBlocks = adjustScheduleBlocksToLocationTz(
    providerTz,
    locationTz,
    SBs
  );
  const nowInLocalTimeZone = utcToZonedTime(new Date(), locationTz);
  const startingDate = startDate ? startDate : getAWSDate(nowInLocalTimeZone);
  const endingDate = getEndingDate(startingDate, numberOfDays);
  const primaryKey = getProviderScheduleId(companyId, providerId);

  const { items: BUTs } = await execReadBySortkey({
    opname: "listProviderSchedules",
    op: scheduleQueries.listAllScheduleItems,
    id: { id: primaryKey },
    skey: { scheduleinfo: { beginsWith: `BUT|` } },
    filter: { deleted: { ne: true } },
  });

  if (BUTs && BUTs.length)
    adjustBUTimesForBookingLocationTimezone(BUTs, providerTz, locationTz);

  const bookedResp = await execReadBySortkey({
    opname: "listProviderSchedules",
    op: scheduleQueries.listTimeblocks,
    id: { id: primaryKey },
    skey: {
      scheduleinfo: { between: [`BK|${startingDate}`, `BK|${endingDate}`] },
    },
    filter: { status: { ne: "CANCELLED" } },
    limit: 500,
  });
  if (bookedResp && bookedResp.items) {
    const found = bookedResp.items.find((bk) =>
      bk.scheduleinfo.includes("|CL-")
    );
    if (found) {
      //booking at company location found, so get geoLocs of the company locations
      const companyLocations = await getCompanyLocations(companyId);
      for (const companyLoc of companyLocations) {
        for (const booking of bookedResp.items) {
          if (
            booking.scheduleinfo.includes(companyLoc.id) &&
            companyLoc.latitude &&
            companyLoc.longitude
          ) {
            if (!booking.latitude && !booking.longitude) {
              booking.latitude = companyLoc.latitude;
              booking.longitude = companyLoc.longitude;
            }
          }
        }
      }
    }
  }

  if (bookedResp && bookedResp.items) {
    // let locationTz = "America/Vancouver";
    adjustGlobalBookingTimesForBookingLocationTimezone(
      locationTz,
      bookedResp.items,
      serviceDuration
    );
  }

  const unreadableSlots = computeAvailableSlots(
    [...timeZoneAdjustedScheduleBlocks, ...BUTs],
    bookedResp.items,
    awsDateToJsDate(startingDate),
    numberOfDays,
    bookingIncrement,
    travelTime,
    geoLoc,
    locationTz
  );

  const readableSlots = toReadableSlots(
    null,
    unreadableSlots.filter((urs) => urs.hasavail),
    bookingIncrement,
    serviceDuration
  );

  const futureSlotsOnly = filterOutTodaysPastSlots(
    readableSlots,
    bookingIntervalMinutes,
    nowInLocalTimeZone
  );
  return returnUnreadable
    ? [unreadableSlots, futureSlotsOnly, bookedResp.items]
    : futureSlotsOnly;
};

async function getCompanyLocations(companyId) {
  try {
    let locationData = await execReadByPK({
      opname: "companyLocationByCompany",
      op: /* GraphQL */ `
        query CompanyLocationByCompany(
          $companyId: String
          $locationnameCreatedAt: ModelCompanyLocationByCompanyCompositeKeyConditionInput
          $sortDirection: ModelSortDirection
          $filter: ModelCompanyLocationFilterInput
          $limit: Int
          $nextToken: String
        ) {
          companyLocationByCompany(
            companyId: $companyId
            locationnameCreatedAt: $locationnameCreatedAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              id
              locationname
              longitude
              latitude
              timezone
              virtual
              active
              deleted
            }
            nextToken
          }
        }
      `,
      id: {
        companyId,
      },
      limit: 500,
    });

    if (locationData?.items && locationData?.items.length > 0) {
      return locationData.items;
    }
    return [];
  } catch (e) {
    console.log("error in ScheduleService getCompanyLocations", e);
    return [];
  }
}

const adjustScheduleBlocksToLocationTz = (fromTz, targetTz, blocks) =>
  targetTz !== fromTz
    ? blocks.map(
        ({ startTime, startDate, endDate, endTime, weekDays, ...rest }) => {
          // make dates in the original tz
          const startDateTimeInScheduleTz = toDate(
            `${startDate}T${startTime}`,
            {
              timeZone: fromTz,
            }
          );
          const endDateTimeInScheduleTz = toDate(`${endDate}T${endTime}`, {
            timeZone: fromTz,
          });
          // convert to target tz
          const startDateTimeInLocationTz = utcToZonedTime(
            startDateTimeInScheduleTz,
            targetTz
          );
          const endDateTimeInLocationTz = utcToZonedTime(
            endDateTimeInScheduleTz,
            targetTz
          );
          //format
          const startDateInLocationTz = format(
            startDateTimeInLocationTz,
            "yyyy-MM-dd"
          );
          const startTimeInLocationTz = format(
            startDateTimeInLocationTz,
            "HH:mm"
          );

          const endDateInLocationTz = format(
            endDateTimeInLocationTz,
            "yyyy-MM-dd"
          );
          const endTimeInLocationTz = format(endDateTimeInLocationTz, "HH:mm");

          const adjustedWeekDays =
            endTimeInLocationTz < startTimeInLocationTz //straddle two days case
              ? uniq(
                  weekDays
                    .split("")
                    .reduce(
                      (agr, dayNum) =>
                        agr.concat(
                          mod(
                            parseInt(dayNum) +
                              1 * (compareTimezones(targetTz, fromTz) ? 1 : -1),
                            7
                          ).toString()
                        ),
                      weekDays
                    )
                ).join("")
              : startDateInLocationTz !== startDate
              ? uniq(
                  // move case
                  weekDays
                    .split("")
                    .map((dayNum) =>
                      mod(
                        parseInt(dayNum) +
                          1 * (compareTimezones(targetTz, fromTz) ? 1 : -1),
                        7
                      ).toString()
                    )
                ).join("")
              : weekDays;
          return {
            ...rest,
            weekDays: adjustedWeekDays,
            startTime: startTimeInLocationTz,
            startDate: startDateInLocationTz,
            endTime: endTimeInLocationTz,
            endDate: endDateInLocationTz,
          };
        }
      )
    : blocks;

function adjustGlobalBookingTimesForBookingLocationTimezone(
  locationTz,
  bookings
) {
  for (let b of bookings) {
    if (b.sdtutc && b.startTime && b.endTime) {
      const bookedMinutes = calculateBookedMinutes(b.startTime, b.endTime);
      const startDateUTC = new Date(b.sdtutc);
      const startDateInLocZone = utcToZonedTime(startDateUTC, locationTz);
      b.startDate = format(startDateInLocZone, "yyyy-MM-dd", {
        timeZone: locationTz,
      });
      b.startTime = format(startDateInLocZone, "HH:mm", {
        timeZone: locationTz,
      });
      const endDateUTC = new Date(startDateInLocZone);
      endDateUTC.setMinutes(endDateUTC.getMinutes() + bookedMinutes);
      b.endTime = format(endDateUTC, "HH:mm", locationTz);
    }
  }
}

function adjustBUTimesForBookingLocationTimezone(BUTs, providerTz, locationTz) {
  for (let sbu of BUTs) {
    const startDateTimeinUtc = zonedTimeToUtc(
      `${sbu.startDate}T${sbu.startTime}:00`,
      providerTz
    );
    const startDateTimeInBookingLoc = utcToZonedTime(
      startDateTimeinUtc,
      locationTz
    );
    sbu.startDate = format(startDateTimeInBookingLoc, "yyyy-MM-dd");
    sbu.startTime = format(startDateTimeInBookingLoc, "HH:mm");

    const endDateTimeinUtc = zonedTimeToUtc(
      `${sbu.endDate}T${sbu.endTime}:00`,
      providerTz
    );
    const endDateTimeInBookingLoc = utcToZonedTime(
      endDateTimeinUtc,
      locationTz
    );
    sbu.endDate = format(endDateTimeInBookingLoc, "yyyy-MM-dd");
    sbu.endTime = format(endDateTimeInBookingLoc, "HH:mm");
  }
}

function calculateBookedMinutes(bkstartTime, bkendTime) {
  //this works only if start and end times are within the same date
  //Jan 10th 11pm to Jan 11th 1am won't work.
  const startHHMM = bkstartTime.split(":");
  const endHHMM = bkendTime.split(":");
  const startTime = new Date(2000, 1, 1, startHHMM[0], startHHMM[1]);
  const endTime = new Date(2000, 1, 1, endHHMM[0], endHHMM[1]);
  const diff = endTime.valueOf() - startTime.valueOf();
  const bookedMinutes = diff / (1000 * 60);
  return bookedMinutes;
}

function filterOutTodaysPastSlots(
  slots,
  bookingIntervalMinutes,
  nowInLocalTimeZone
) {
  const checkDateTime = new Date(
    nowInLocalTimeZone.getTime() + bookingIntervalMinutes * 60 * 1000
  );
  slots.forEach((day) => {
    day.slots = day.slots.filter((slot) =>
      isFutureSlot_v3(slot, day.date, checkDateTime)
    );
  });

  return slots;
}

function filterByTimesofday(slots, timesofday) {
  slots.forEach((day) => {
    day.slots = day.slots.filter((slot) => timesofday[slot.tfd]);
  });

  return slots;
}

function isFutureSlot_v3(slot, dayDate, checkDateTime) {
  //Calculate millis of the slot
  const dayDateMillis = dayDate.getTime();
  let slotDate = new Date(dayDateMillis);
  slotDate.setHours(Number.parseInt(slot.start24.slice(0, 2)));
  slotDate.setMinutes(Number.parseInt(slot.start24.slice(-2)));
  return slotDate.getTime() > checkDateTime.getTime();
}

function computeAvailableSlots(
  timeBlocks,
  booked,
  selectedStartDateTime,
  nd,
  bookingIncrement,
  travelTime,
  geoLoc,
  locationTz
) {
  travelTime = travelTime ? travelTime : 0;

  //Create the placeholder array of by-date slots objects for asked numdays
  //For earch day create slots array based on step (15,30,60 mins)
  let slotMapObjects = new Array(nd).fill(null).map((a, dayIndex) => ({
    date: addDays(selectedStartDateTime, dayIndex),
    hasavail: false,
    slotmap: Array.from({ length: SLOTSINADAY }),
    availableIntervals: [],
  }));

  //iterate over the AVAILABLE timeblocks
  //  for each AVAILABLE timeblock
  //    based on startTime and endTime (and step), calculate start index and stop index to be marked as 1 (meaning available)
  //    [TBD: it is possible to have this number higher than 1 indicating that multiple booking is possible but need to design the feature end-to-end]
  //     Once start and stop indices are calculated, for each day in the slotMapArray which falls within the AVAILABLE timeblock's range
  //     and it is the workingday of the provider, fill the availability in each day's slotmap

  timeBlocks
    .filter((timeblock) => timeblock.type === "AVAILABLE")
    .forEach(({ weekDays, startTime, startDate, endDate, endTime }) => {
      slotMapObjects
        .filter((slotMapObject) => {
          return (
            slotMapObject.date >= awsDateToJsDate(startDate) &&
            slotMapObject.date <= awsDateToJsDate(endDate) &&
            worksonday(slotMapObject.date, weekDays)
          );
        })
        .forEach((slotMapObject) => {
          slotMapObject.hasavail = true;
          const availableIntervalsForCurrentBlock =
            endTime >= startTime
              ? [
                  // regular interval from start time to end  time
                  [getIndexByTime(startTime), getIndexByTime(endTime)],
                ]
              : [
                  // schedule is split between two days because of time zone so two intervals from start of day till end time (the piece from last day)
                  // and from start time till end of day
                  ...(workedTheDayBefore(slotMapObject.date, weekDays)
                    ? [[0, getIndexByTime(endTime)]]
                    : []),
                  ...(workedTheDayAfter(slotMapObject.date, weekDays)
                    ? [[getIndexByTime(startTime), SLOTSINADAY]]
                    : []),
                ];
          // create the map from available intervals
          const slotsForCurrentBlock = Array.from(
            { length: SLOTSINADAY },
            (val, index) =>
              availableIntervalsForCurrentBlock.some(
                (availableInterval) =>
                  index >= availableInterval[0] && index <= availableInterval[1]
              )
                ? 1
                : undefined
          );
          // merge current block slots into the slots array for that day
          slotMapObject.slotmap = slotMapObject.slotmap.map((val, i) =>
            val === undefined && slotsForCurrentBlock[i] === undefined
              ? undefined
              : val || slotsForCurrentBlock[i]
          );
          slotMapObject.availableIntervals =
            slotMapObject.availableIntervals.concat(
              availableIntervalsForCurrentBlock
            );
        });
    });

  //TODO: do we need to check for UNAVAILABLE?
  timeBlocks
    .filter((timeblock) => timeblock.type === "UNAVAILABLE")
    .forEach((timeblock) => {
      slotMapObjects
        .filter(
          (slotMapObject) =>
            slotMapObject.hasavail &&
            hasUnavailability(
              slotMapObject.date,
              awsDateToJsDate(timeblock.startDate),
              awsDateToJsDate(timeblock.endDate),
              timeblock.weekDays
            )
        )
        .forEach((slotMapObject) => {
          markUnAvailability(
            slotMapObject.slotmap,
            getIndexByTime(timeblock.startTime),
            getIndexByTime(timeblock.endTime),
            0, //Travel timee zero for
            null,
            null,
            bookingIncrement
          );
        });
    });
  // iterate over BOOKED blocks (appointments already booked)
  // For each booked appointment,
  //    compute start and stop index based on the start and end time of the appointment
  //    get the date of the appointment
  //    find the day in slotMapArray that matches the appointment date
  //    for the matched day in the slotMapArray, mark the slotmap's elements as booked (i.e. unavailable)
  booked
    .filter(({ type }) => type === "BOOKED")
    .forEach((timeblock) => {
      slotMapObjects
        .filter(
          (slotmap) =>
            slotmap.hasavail &&
            slotmap.date.getTime() ===
              awsDateToJsDate(timeblock.startDate).getTime()
        )
        .forEach((slotmap) => {
          markUnAvailability(
            slotmap.slotmap,
            getIndexByTime(timeblock.startTime),
            getIndexByTime(timeblock.endTime),
            travelTime,
            timeblock,
            geoLoc,
            bookingIncrement
          );
        });
    });
  return slotMapObjects;
}

function hasUnavailability(
  slotmapDate,
  unavStartDate,
  unavEndDate,
  unavWeekDays
) {
  // if SBU block has weekdays, weekday must match
  // otherwise only date match is required

  if (
    slotmapDate.getTime() >= unavStartDate.getTime() &&
    slotmapDate.getTime() <= unavEndDate.getTime()
  ) {
    if (unavWeekDays && unavWeekDays.length) {
      let unavaOnWeekday =
        unavWeekDays.indexOf(slotmapDate.getDay()) !== -1 ? true : false;
      if (!unavaOnWeekday) return false;
    }
    return true;
  } else return false;
}
//sma slotMapArray, step 15, 30, 60 mins
function toReadableSlots(schedule, sma, step, serviceDuration) {
  const readableArray = [];
  sma.forEach((d) => {
    readableArray.push({
      // schid: schedule.id,
      date: d.date,
      slots: toReableArray(
        d.slotmap,
        step,
        d.date,
        d.availableIntervals,
        serviceDuration
      ),
    });
  });
  return readableArray;
}
//slotmap array of 0s and 1s indicating the availability
//convert info in the slotmap into the start times of the available slots
function toReableArray(
  slotmap,
  step,
  date,
  availableIntervals,
  serviceDuration
) {
  const readableSlots = [];
  const displaybleSlots = displaySlotsByStep2(
    step,
    slotmap,
    TIMEMAP,
    serviceDuration,
    availableIntervals
  );
  if (displaybleSlots.length > 0) {
    displaybleSlots.forEach((ds) => {
      const datetime = new Date(date.getTime());
      datetime.setHours(ds.hr);
      datetime.setMinutes(ds.mins);
      readableSlots.push({
        len: step,
        start24: ds.tstr24,
        start12: ds.tstr12,
        datetime,
        tfd: ds.tfd,
        hr: ds.hr,
      });
    });
  }

  return readableSlots;
}

function getAWSDate(date) {
  let oy = { year: "numeric" };
  let YYYY = date.toLocaleDateString("en-US", oy);
  let om = { month: "2-digit" };
  let MM = date.toLocaleDateString("en-US", om);
  let od = { day: "2-digit" };
  let DD = date.toLocaleDateString("en-US", od);
  return `${YYYY}-${MM}-${DD}`;
}

function getEndingDate(sd, nd) {
  const ed = awsDateToJsDate(sd);
  ed.setDate(ed.getDate() + nd);
  return getAWSDate(ed);
}

function awsDateToJsDate(awsDate) {
  // from YYYY-MM-DD to local timezone
  const dateparts = awsDate.split("-");
  return new Date(
    parseInt(dateparts[0]),
    parseInt(dateparts[1] - 1),
    parseInt(dateparts[2])
  ); //yes, local time
}

function awsDateToJsEndDate(awsDate) {
  const d = awsDateToJsDate(awsDate);
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  d.setMilliseconds(999);
  return d;
}

function worksonday(sample, range) {
  if (range && sample) {
    return range.indexOf(sample.getDay()) !== -1 ? true : false;
  } else return true; //no range, so weekday does not matter.
}
const workedTheDayBefore = (day, shceduleWeekday) =>
  shceduleWeekday.indexOf(mod(day.getDay() - 1, 7)) !== -1;

const workedTheDayAfter = (day, shceduleWeekday) =>
  shceduleWeekday.indexOf(mod(day.getDay() + 1, 7)) !== -1;

function areSame(d1, d2) {
  return (
    d1 &&
    d2 &&
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
}
// aDate - appointment date and time to check for validity
// dayCount - number associated with the unit in dayType
// dayType - days, weeks, months
const validateApptDate = async (
  aDate,
  scheduleIds,
  dayCount,
  dayType,
  bookingIntervalMinutes,
  serviceDuration,
  travelTime,
  geoLoc,
  bookingIncrement,
  selectedLocation,
  companyId,
  provider
) => {
  travelTime = travelTime ? travelTime : 0;
  const { id: providerId, timezone: providerTz } = provider;
  //Get timeblocks
  const availableTBs = await getSBsByScheduleInfo(
    getProviderScheduleId(companyId, providerId),
    scheduleIds
  );
  //if apptDate's day has the schedule
  const doesworkondate = isScheduleAvailableOnApptDate(aDate, availableTBs);
  if (doesworkondate.result) {
    //provider works on the booking date
    /////....... start of new Code
    const slotsByDate = await getSlotsForSbs({
      SBs: availableTBs,
      startDate: getAWSDate(aDate),
      numberOfDays: 1,
      bookingIntervalMinutes,
      serviceDuration,
      travelTime,
      geoLoc,
      bookingIncrement,
      selectedLocation,
      companyId,
      providerId,
      providerTz,
      locationTz: selectedLocation.timezone,
    });
    const apptTime24hr = get24hrTime(aDate);
    for (const day of slotsByDate) {
      if (day && areSame(day.date, aDate))
        for (const slot of day.slots) {
          if (slot.start24 === apptTime24hr) {
            return {
              isValid: true,
              hasAlternate: false,
              alternateSlot: {},
              originalSlot: aDate,
              dateInSchedule: doesworkondate.dateInSchedule,
            };
          }
        }
    }
    for (const day of slotsByDate) {
      if (day && areSame(day.date, aDate)) {
        if (day && day.slots && day.slots.length) {
          for (const slot of day.slots) {
            slot.diff = Math.abs(slot.datetime.getTime() - aDate.getTime());
          }
          day.slots.sort(function (a, b) {
            return a.diff - b.diff;
          });
          return {
            isValid: false,
            hasAlternate: true,
            alternateSlot: { date: day.date, slot: day.slots[0] },
            originalSlot: aDate,
            dateInSchedule: doesworkondate.dateInSchedule,
          };
        } else {
          return {
            isValid: false,
            hasAlternate: false,
            alternateSlot: {},
            originalSlot: aDate,
            dateInSchedule: doesworkondate.dateInSchedule,
          };
        }
      }
    }
    ///// End of new Code.....................
  } else {
    //suggest alternate

    //Get slots for one week
    const slotSearchDateRange = decideAlternateSlotSearchDateRange(
      aDate,
      dayCount,
      dayType
    );
    if (slotSearchDateRange.result === false) {
      return {
        isValid: false,
        hasAlternate: false,
        alternateSlot: {},
        originalSlot: aDate,
        dateInSchedule: doesworkondate.dateInSchedule,
      };
    }
    const slotsBySchedule = await getSlotsForSbs({
      SBs: availableTBs,
      startDate: getAWSDate(aDate),
      numberOfDays: 1,
      bookingIntervalMinutes,
      serviceDuration,
      travelTime,
      geoLoc,
      bookingIncrement,
      selectedLocation,
      companyId,
      providerId,
      providerTz,
      locationTz: selectedLocation.timezone,
    });
    const slotsByDate = slotsBySchedule[0]?.slots;
    const apptTime24hr = get24hrTime(aDate);
    if (slotsByDate && slotsByDate.length) {
      for (const day of slotsByDate) {
        for (const slot of day.slots) {
          if (slot.start24 === apptTime24hr) {
            return {
              isValid: false,
              hasAlternate: true,
              alternateSlot: { date: day.date, slot: slot },
              originalSlot: aDate,
              dateInSchedule: doesworkondate.dateInSchedule,
            };
          }
        }
      }
    }
  }
  return {
    isValid: false,
    hasAlternate: false,
    alternateSlot: {},
    originalSlot: aDate,
    dateInSchedule: doesworkondate.dateInSchedule,
  };
};

const decideAlternateSlotSearchDateRange = (apptDate, dayCount, dayType) => {
  if (dayType === "days" && dayCount === 1) {
    //No alternate slot can be searched
    return { result: false };
  }
  if (dayType === "days") {
    return {
      result: true,
      startDate: addDays(apptDate, 1),
      numdays: dayCount === 2 ? 0 : dayCount - 1,
    };
  }
  if (dayType === "weeks") {
    return {
      result: true,
      startDate: addDays(apptDate, 1),
      numdays: dayCount === 1 ? 3 : (dayCount * 7) / 2,
    };
  }
  if (dayType === "months") {
    return {
      result: true,
      startDate: addDays(apptDate, 1),
      numdays: dayCount === 1 ? 14 : (dayCount * 30) / 2,
    };
  }
  return {
    result: false,
  };
};

const isScheduleAvailableOnApptDate = (aDate, avTBs) => {
  const ret = {
    result: false,
    dosuggestalternate: false,
    dateInSchedule: false,
  };
  let dateInSchedule = false;
  for (let tb of avTBs) {
    if (
      aDate >= awsDateToJsDate(tb.startDate) &&
      aDate <= awsDateToJsEndDate(tb.endDate)
    ) {
      dateInSchedule = true;
    }
  }
  ret.dateInSchedule = dateInSchedule;
  for (let tb of avTBs) {
    if (
      aDate >= awsDateToJsDate(tb.startDate) &&
      aDate <= awsDateToJsEndDate(tb.endDate)
    ) {
      if (worksonday(aDate, tb.weekDays)) {
        ret.result = true;
        ret.dosuggestalternate = false;
      } else {
        if (!ret.result) {
          ret.result = false;
          ret.dosuggestalternate = true;
        }
      }
    } else {
      if (!ret.result) {
        ret.result = false;
        ret.dosuggestalternate = false;
      }
    }
  }
  return ret;
};

const getSBsByScheduleInfo = async (pk, schInfos) => {
  const { items: scheduleBlocks } = await execReadBySortkey({
    opname: "listProviderSchedules",
    op: scheduleQueries.listTimeblocks,
    id: { id: pk },
    skey: { scheduleinfo: { beginsWith: `SB|` } },
    filter: {
      and: [{ active: { ne: false } }, { deleted: { ne: true } }],
    },
    limit: 20,
  });
  const filteredBlocks = scheduleBlocks.filter(({ scheduleinfo }) =>
    schInfos.some((schInfos) => scheduleinfo.indexOf(schInfos) !== -1)
  );
  if (filteredBlocks) {
    for (let tb of filteredBlocks) {
      createScheduleDescription(tb);
    }
  }
  return filteredBlocks;
};

function createScheduleDescription(tb) {
  tb.timeRange = `${withAMPM(tb.startTime)} - ${withAMPM(tb.endTime)}`;
  tb.dateRange = `${formatted(tb.startDate)} - ${formatted(tb.endDate)}`;
  const weekDaysArr =
    tb?.weekDays === "" ? ALLDAYSARRAY : tb.weekDays.split("");
  if (weekDaysArr) {
    weekDaysArr.sort();
    let repeat = "";
    if (weekDaysArr.length === 7) {
      repeat = "Every day";
    } else if (weekDaysArr.length === 5 && isWeekdaysOnly(weekDaysArr)) {
      repeat = "Every weekday";
    } else {
      repeat = "Every ";
      const weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      for (const we of weekDaysArr) {
        repeat = repeat + weeks[we] + " ";
      }
    }
    tb.repeat = repeat;
  }
}

function isWeekdaysOnly(wa) {
  wa.sort();
  for (let i = 0; i <= 4; i++) {
    if (!(parseInt(wa[i]) === i + 1)) return false;
  }
  return true;
}
function formatted(d) {
  return new Date(d).toLocaleDateString("en-US", {
    year: "2-digit",
    month: "short",
    day: "numeric",
  });
}

function withAMPM(t) {
  if (!t) return "";
  const hm = t.split(":");
  let hh = Number.parseInt(hm[0]);
  let hr = hh === 0 ? 12 : hh < 13 ? hh : hh - 12;
  let hrstr = hr;
  let ampm = hh < 12 ? "AM" : "PM";
  if (hm[1] === "00") return `${hrstr} ${ampm}`;
  else return `${hrstr}:${hm[1]} ${ampm}`;
}

const getProviderLocation = async (id) => {
  const data = await API.graphql(
    graphqlOperation(
      `query GetProviderLocation($id: ID!) {
      getProviderLocation(id: $id) {
        id
        name 
        timezone
      }
    }`,
      {
        id,
      }
    )
  );
  return data.data.getProviderLocation;
};

const getCompanyLocationGeoCoordinates = async (scheduleinfo) => {
  try {
    if (scheduleinfo && scheduleinfo.includes("|CL-")) {
      const parts = scheduleinfo.split("|");
      const locationId = parts[1].slice(3);
      const companyLocation = await getCompanyLocation(locationId);
      if (companyLocation) {
        return {
          lat: companyLocation.latitude,
          lng: companyLocation.longitude,
        };
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};
const getCompanyLocation = async (id) => {
  const data = await API.graphql(
    graphqlOperation(
      `query GetCompanyLocation($id: ID!) {
        getCompanyLocation(id: $id) {
        id
        locationname 
        timezone
        latitude
        longitude
      }
    }`,
      {
        id,
      }
    )
  );
  return data.data.getCompanyLocation;
};

const get24hrTime = (aDate) => {
  return aDate
    ? aDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h24",
      })
    : "";
};
function getIndexByTime(t) {
  const parts = t.split(":");
  const h = parseInt(parts[0]);
  const m = parseInt(parts[1]);
  const index = (h * 60 + m) / SLOTUNITMINS;
  return index;
}

function markUnAvailability(
  sm,
  si,
  ei,
  travelTime,
  tb,
  geoLoc,
  bookingIncrement
) {
  if (tb && tb.latitude && tb.longitude && geoLoc) {
    if (!includeTravelTime(tb, geoLoc)) travelTime = 0;
  }
  if (travelTime && travelTime > 0) {
    let ttIndLen = travelTime / SLOTUNITMINS;
    si = si - ttIndLen;
    ei = ei + ttIndLen;
    let clawbackInd = ei % (bookingIncrement / SLOTUNITMINS);
    if (clawbackInd <= 1) {
      //10 mins into the next half an hour like 10:10 or 10:40
      ei = ei - clawbackInd;
    }
  }
  //in above block ei could correspond to endTime 00:00 i.e. ei=0 (or near midnight like 11:50pm), and hence its travel time adjusted ei could be between 0 and 5 of the NEXT  DAY.
  // we have no way of storing and presenting this fact to users so reset the endTime back 00:00
  if (ei < si) ei = SLOTSINADAY;

  for (let s = si; s <= ei - 1; s++) {
    sm[s] = 0;
  }
}

function includeTravelTime(tb, geoLoc) {
  if (tb && tb.latitude && tb.longitude && geoLoc) {
    const tbLat = Number(tb.latitude);
    const tbLng = Number(tb.longitude);
    const bookLat = Number(geoLoc.lat);
    const bookLng = Number(geoLoc.lng);
    const lat = tbLat - bookLat;
    const lng = tbLng - bookLng;
    const dLat = (lat * Math.PI) / 180;
    const dLng = (lng * Math.PI) / 180;
    const bookLatRad = (bookLat * Math.PI) / 180;
    const tbLatRad = (tbLat * Math.PI) / 180;

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(bookLatRad) *
        Math.cos(tbLatRad) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = EARTHRADIUS * c;
    if (d < NOTRAVELTIMERADIUS) return false;
  }
  return true;
}

function calcDist(fromGeo, toGeo) {
  try {
    if (
      fromGeo.latitude &&
      fromGeo.longitude &&
      toGeo.latitude &&
      toGeo.longitude
    ) {
      if (
        isNaN(fromGeo.latitude) ||
        isNaN(fromGeo.longitude) ||
        isNaN(toGeo.latitude) ||
        isNaN(toGeo.longitude)
      )
        return { km: 0, miles: 0, usable: false };
      const fromLat = Number(fromGeo.latitude);
      const fromLng = Number(fromGeo.longitude);
      const toLat = Number(toGeo.latitude);
      const toLng = Number(toGeo.longitude);
      const lat = fromLat - toLat;
      const lng = fromLng - toLng;
      const dLat = (lat * Math.PI) / 180;
      const dLng = (lng * Math.PI) / 180;
      const toLatRad = (toLat * Math.PI) / 180;
      const fromLatRad = (fromLat * Math.PI) / 180;

      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toLatRad) *
          Math.cos(fromLatRad) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = EARTHRADIUS * c;
      if (d === 0) return { km: 0, miles: 0, usable: false };
      let km = round(Number(d / 1000), 1);
      let miles = round(Number((d / 1000) * 0.621371), 1);
      return { km, miles, usable: true };
    } else {
      return { km: 0, miles: 0, usable: false };
    }
  } catch (e) {
    console.log("error while calculating distance", e);
    return { km: 0, miles: 0, usable: false };
  }
}

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function displaySlotsByStep2(
  step,
  sm,
  tmap,
  serviceDuration,
  availableIntervals
) {
  let stepstarted = false;
  const steplen = step / SLOTUNITMINS;
  const serviceDurationlen = serviceDuration / SLOTUNITMINS;
  let returnStartTimes = [];

  availableIntervals.forEach((availableInterval) => {
    const foundStartTimes = [];
    let referenceStartIndex = availableInterval[0];
    for (let s = availableInterval[0]; s < availableInterval[1]; s++) {
      if (sm[s] === 1) {
        if (stepstarted) {
          //existing step
          for (let fst of foundStartTimes) {
            if (fst.live) fst.uc += 1;
          }
          if ((s - referenceStartIndex) % steplen === 0) {
            foundStartTimes.push({ st: tmap[s], uc: 1, live: true });
            stepstarted = true;
            referenceStartIndex = s;
          }
        } else {
          //new step started
          foundStartTimes.push({ st: tmap[s], uc: 1, live: true });
          stepstarted = true;
          referenceStartIndex = s;
        }
      } else {
        for (let fst of foundStartTimes) {
          fst.live = false;
        }
        stepstarted = false;
      }
    }
    returnStartTimes = returnStartTimes.concat(
      foundStartTimes
        .filter((fst) => fst.uc >= serviceDurationlen)
        .map((fst) => fst.st)
    );
  });
  return returnStartTimes;
}

function buildTimeMap() {
  const tmap = new Array(SLOTSINADAY);
  for (let s = 0; s < SLOTSINADAY; s++) {
    const hr = Math.floor(s / SLOTSINHOUR);
    const mins = (s % SLOTSINHOUR) * SLOTUNITMINS;
    const tstr24 = `${hr < 10 ? "0" : ""}${hr}:${mins < 10 ? "0" : ""}${mins}`;
    const tfd =
      hr > 6 && hr < 12
        ? 1
        : hr > 11 && hr < 17
        ? 2
        : hr > 16 && hr <= 23
        ? 3
        : 4;
    const tstr12 = `${hr < 13 ? hr : hr - 12}:${mins < 10 ? "0" : ""}${mins} ${
      hr > 11 ? "PM" : "AM"
    }`;
    tmap[s] = { tstr24, tstr12, tfd, hr, mins };
  }
  return tmap;
}

//For UI : _getSchedules
const _getSchedules = async ({ companyId, providerId }) => {
  const response = await execReadBySortkey({
    opname: "listProviderSchedules",
    op: scheduleQueries.listAllScheduleItems,
    id: { id: getProviderScheduleId(companyId, providerId) },
    skey: { scheduleinfo: { beginsWith: "S" } },
    filter: { and: [{ active: { ne: false } }, { deleted: { ne: true } }] },
    limit: process.env.REACT_APP_LISTLIMIT,
  });

  //TODO: check if no items in the response

  const allSchedules = response.items.filter((schitem) =>
    schitem.scheduleinfo.startsWith("SC|")
  );

  const allLocationIds = allSchedules.reduce(
    (agrigate, { locations = "[]" }) => [
      ...agrigate,
      ...JSON.parse(locations).map((lc) => stripLocationPrefix(lc)),
    ],
    []
  );

  const uniqueLocations = uniq(allLocationIds);

  const allServices = response.items.filter((schitem) =>
    schitem.scheduleinfo.startsWith("SR|")
  );
  const allSBs = response.items.filter((schitem) =>
    schitem.scheduleinfo.startsWith("SB|")
  );

  //Get Locations
  const ORlocations = uniqueLocations.map((lId) => {
    return {
      id: {
        eq: lId,
      },
    };
  });
  const locationsFilter = {
    or: ORlocations,
  };
  const companyLocations = await execRead({
    opname: "listCompanyLocations",
    op: queries.listCompanyLocations,
    filter: locationsFilter,
    limit: process.env.REACT_APP_LISTLIMIT,
  });

  const pd = await getProviderDataForSchedules(providerId);

  const returnSchedules = allSchedules.map((schedule) => {
    const scheduleLocationIds = JSON.parse(schedule.locations).map((id) =>
      id.substring(3)
    );
    return {
      ...schedule,
      id: schedule.id + SCHEDPKSKSPLITAT + schedule.scheduleinfo, //to support DataTableWrapper
      SBs: allSBs
        .filter((tb) => tb.scheduleinfo.includes(schedule.scheduleinfo))
        .map((tb) => {
          createScheduleDescription(tb);
          return {
            ...tb,
            id: tb.id + SCHEDPKSKSPLITAT + tb.scheduleinfo,
          };
        }),
      companyLocations: companyLocations.items.filter(({ id }) =>
        scheduleLocationIds.includes(id)
      ),
      services: allServices
        .filter(
          (serv) =>
            serv.scheduleinfo.endsWith(schedule.scheduleinfo) ||
            serv.scheduleinfo.includes(schedule.scheduleinfo.split("|")[1])
        )
        .map((serv) => {
          const servicetypeId = serv.scheduleinfo.split("|")[1];
          return {
            scheduleinfo: serv.scheduleinfo,
            servicetypeId,
            name: getServiceName(pd.servicetypes, servicetypeId),
          };
        }),
    };
  });
  return returnSchedules;
};

function getServiceName(providerServices, servicetypeId) {
  let serviceTypeName = null;
  providerServices.forEach((st) => {
    if (servicetypeId === st.servicetype.id) {
      serviceTypeName = st.servicetype.name;
    }
  });
  return serviceTypeName;
}
export function getProviderScheduleId(cid, pid) {
  return `C-${cid}|P-${pid}`;
}

const getProviderDataForSchedules = async (providerid) => {
  const providerData = await API.graphql(
    graphqlOperation(scheduleQueries.providerDataForScheduleQuery, {
      id: providerid,
    })
  );

  let providerDataForSchedule = {};

  if (
    providerData &&
    providerData.data &&
    providerData.data.getProvider &&
    providerData.data.getProvider.servicetypes &&
    providerData.data.getProvider.servicetypes.items
  ) {
    providerDataForSchedule = {
      ...providerDataForSchedule,
      servicetypes: providerData.data.getProvider.servicetypes.items.filter(
        (s) => !(true === s.servicetype.deleted)
      ),
    };
  }
  if (
    providerData &&
    providerData.data &&
    providerData.data.getProvider &&
    providerData.data.getProvider.locations &&
    providerData.data.getProvider.locations.items
  ) {
    providerDataForSchedule = {
      ...providerDataForSchedule,
      locations: providerData.data.getProvider.locations.items.filter(
        (pl) => !(true === pl.deleted)
      ),
    };
  }

  return providerDataForSchedule;
};

async function checkPackageBookedSlotsValid(bookingState) {
  const slots = bookingState.heldPackageSlots;
  const bookings = bookingState.packageBookedSlots;
  if (slots?.length !== bookings?.length) {
    return false;
  }
  //if each slot exists in the db
  for (let slot of slots) {
    const idsplit = slot.id.split(SCHEDPKSKSPLITAT);
    const result = await API.graphql(
      graphqlOperation(
        /* GraphQL */ `
          query GetProviderSchedule($id: ID!, $scheduleinfo: String!) {
            getProviderSchedule(id: $id, scheduleinfo: $scheduleinfo) {
              id
              scheduleinfo
            }
          }
        `,
        {
          id: idsplit[0],
          scheduleinfo: idsplit[1],
        }
      )
    );
    return result.data.getProviderSchedule?.scheduleinfo === slot.scheduleinfo;
  }
  // check if order of slots and bookings match in the respective arrays
  let index = 0;
  for (let b of bookings) {
    if (b.dateInfo.dtstamp_str !== slots[index]?.sdtutc) {
      return false;
    }
    index = index + 1;
  }
  return true;
}
async function checkSelectedSlotStillAvailable(bookingState) {
  const pksk = getProviderScheduleId(
    bookingState.companyId,
    bookingState.provider.id
  );
  let startDate = new Date(bookingState.selectedslot.date);
  startDate = getAWSDate(startDate);
  let startTime = bookingState.selectedslot.label;
  const bookedResp = await execReadBySortkey({
    opname: "listProviderSchedules",
    op: scheduleQueries.listTimeblocks,
    id: { id: pksk[0] },
    skey: {
      scheduleinfo: { beginsWith: `BK|${startDate} ${startTime}` },
    },
    filter: { status: { ne: "CANCELLED" } },
    limit: 500,
  });

  if (bookedResp && bookedResp.items) {
    if (bookedResp.items.length) {
      const locationTz = bookingState.serviceLocationObj.timezone;

      let allSlotsHaveTz = true;
      let slotTzMatch = false;

      for (let slot of bookedResp.items) {
        if (!slot.tz) allSlotsHaveTz = false;
        if (slot.tz === locationTz) {
          slotTzMatch = true;
        }
      }
      if (allSlotsHaveTz) return !slotTzMatch;

      return false;
    }
  }
  return true;
}

async function checkSlotStillAvailablePkgBkgFlow(
  selectedslot,
  pk,
  { timezone: locationTz }
) {
  let startDate = new Date(selectedslot.date);
  startDate = getAWSDate(startDate);
  let startTime = selectedslot.label;
  const bookedResp = await execReadBySortkey({
    opname: "listProviderSchedules",
    op: scheduleQueries.listTimeblocks,
    id: { id: pk },
    skey: {
      scheduleinfo: { beginsWith: `BK|${startDate} ${startTime}` },
    },
    filter: { status: { ne: "CANCELLED" } },
    limit: 500,
  });

  if (bookedResp && bookedResp.items) {
    if (bookedResp.items.length) {
      let allSlotsHaveTz = true;
      let slotTzMatch = false;

      for (let slot of bookedResp.items) {
        if (!slot.tz) allSlotsHaveTz = false;
        if (slot.tz === locationTz) {
          slotTzMatch = true;
        }
      }
      if (allSlotsHaveTz) return !slotTzMatch;

      return false;
    }
  }
  return true;
}

function getPhysicalLocationGeoLoc(serviceLocationObj) {
  if (serviceLocationObj) {
    let locObj = serviceLocationObj;
    if (locObj.latitude && locObj.longitude) {
      return {
        lat: locObj.latitude,
        lng: locObj.longitude,
      };
    }
  }
  return;
}

async function getClusteredSlots(
  bookingState,
  lookAheadDays = DEFAULT_LOOKAHEAD_DAYS_FOR_CLUSTERING,
  startDate = new Date(),
  includeFullDayAnyTimeSlots = false,
  locationTz
) {
  try {
    let timeSuggestionsEnabled = false;
    let firstBookingAnchorTime;
    let maxTravelTimeMinutes;
    if (bookingState && bookingState.company?.SuggestionConfig) {
      const suggestionConfigObj = JSON.parse(
        bookingState.company.SuggestionConfig
      );
      timeSuggestionsEnabled = suggestionConfigObj.isEnabled;
      firstBookingAnchorTime = suggestionConfigObj.timeOfFirstBookingOfTheDay;
      maxTravelTimeMinutes = suggestionConfigObj.maxTravelTimeMinutes;
    }
    if (bookingState.provider.maxTravelTime)
      maxTravelTimeMinutes = bookingState.provider.maxTravelTime;
    if (timeSuggestionsEnabled) {
      let geoLoc = bookingState.isRemoteLocation
        ? bookingState.remoteAddressCoordinates
        : getPhysicalLocationGeoLoc(bookingState.serviceLocationObj);
      if (geoLoc) {
        const clusteredSlotsResponse = await API.post(
          "bookingapi",
          "/clusteredslots",
          {
            body: {
              companyId: bookingState.company.id,
              providerId: bookingState.provider.id,
              providerSBs: bookingState.provider.SBs,
              startdate: getAWSDate(startDate),
              numdays: lookAheadDays,
              bookingIntervalMinutes:
                bookingState.company.bookingIntervalMinutes,
              serviceDuration: bookingState.serviceType.minutes,
              maxTravelTime: maxTravelTimeMinutes, // Provider.maxTravelTime (when becomes available) or company.SuggestionConfig.maxTravelTimeMinutes
              geoLoc,
              bookingIncrement: 15,
              BROWSER_TZ: Intl.DateTimeFormat().resolvedOptions().timeZone, //BROWSER_TZ
              firstBookingAnchorTime,
              includeFullDayAnyTimeSlots,
              locationTz,
            },
          }
        );
        //ensure unique slots
        if (clusteredSlotsResponse && clusteredSlotsResponse.clusteredslots) {
          if (clusteredSlotsResponse.clusteredslots.slots?.length) {
            const seenDates = new Set();
            clusteredSlotsResponse.clusteredslots.slots =
              clusteredSlotsResponse.clusteredslots.slots.filter((obj) => {
                if (seenDates.has(obj.clusteredReadableSlot.datetime)) {
                  return false;
                } else {
                  seenDates.add(obj.clusteredReadableSlot.datetime);
                  return true;
                }
              });
          }
        }
        return clusteredSlotsResponse;
      }
    }
  } catch (e) {
    console.log("getClusteredSlots error:", e);
  }
}
async function getTimeSuggestions(
  locationTz,
  bookingState,
  lookAheadDays = DEFAULT_LOOKAHEAD_DAYS_FOR_CLUSTERING,
  startDate = new Date(),
  maxNumberOfSlots = DEFAULT_MAX_NUM_OF_SUGGESTED_SLOTS_TO_DISPALY
) {
  try {
    const result = await getClusteredSlots(
      bookingState,
      lookAheadDays,
      startDate,
      false,
      locationTz
    );
    if (result) {
      if (result && result.clusteredslots) {
        if (result.clusteredslots.slots?.length > maxNumberOfSlots)
          result.clusteredslots.slots = result.clusteredslots.slots.slice(
            0,
            maxNumberOfSlots
          );
        return result.clusteredslots;
      }
    } else {
      return {
        slots: [],
        fullDays: [],
      };
    }
  } catch (e) {
    console.log(e);
    return {
      slots: [],
      fullDays: [],
    };
  }
}

async function getPackageSuggestedTimes(apptDates, bookingState, locationTz) {
  try {
    const suggestedPackageDates = [];
    for (let apptDate of apptDates) {
      const result = await getClusteredSlots(
        bookingState,
        1,
        apptDate.date,
        false,
        locationTz
      );
      if (result) {
        if (result && result.clusteredslots) {
          if (result.clusteredslots.fullDays.length === 1) {
            // check YYYY-MM-DD of apptDate.date with fullDays[0].date's YYYY-MM-DD
            if (
              moment(apptDate.date).format("YYYY-MM-DD") ===
              moment(result.clusteredslots.fullDays[0].date).format(
                "YYYY-MM-DD"
              )
            )
              suggestedPackageDates.push(apptDate); //display date info is already there
          }
          if (result.clusteredslots.slots?.length) {
            const milisecondsOfCurrentApptDate = new Date(
              apptDate.date
            ).valueOf();
            for (let suggestedSlot of result.clusteredslots.slots) {
              const clusteredApptMilisconds = new Date(
                suggestedSlot.clusteredReadableSlot.datetime
              ).valueOf();
              suggestedSlot.clusteredReadableSlot.diffToUserSelectedDateTime =
                Math.abs(
                  clusteredApptMilisconds - milisecondsOfCurrentApptDate
                );
            }
            result.clusteredslots.slots.sort((s1, s2) => {
              if (
                s1.clusteredReadableSlot.diffToUserSelectedDateTime >
                s2.clusteredReadableSlot.diffToUserSelectedDateTime
              )
                return 1;
              else if (
                s1.clusteredReadableSlot.diffToUserSelectedDateTime <
                s2.clusteredReadableSlot.diffToUserSelectedDateTime
              )
                return -1;
              else return 0;
            });
            const suggestedSlotToBePresented =
              result.clusteredslots.slots[0].clusteredReadableSlot;
            suggestedSlotToBePresented.datetime = new Date(
              suggestedSlotToBePresented.datetime
            );
            suggestedPackageDates.push({
              date: suggestedSlotToBePresented.datetime,
              key: suggestedSlotToBePresented.datetime.toISOString(),
              selected: true,
              validity: {
                isValid:
                  suggestedSlotToBePresented.diffToUserSelectedDateTime === 0
                    ? true
                    : false,
                hasAlternate:
                  suggestedSlotToBePresented.diffToUserSelectedDateTime === 0
                    ? false
                    : true,
                originalSlot: apptDate.date,
                dateInSchedule: true,
                ...(suggestedSlotToBePresented.diffToUserSelectedDateTime && {
                  alternateSlot: {
                    date: suggestedSlotToBePresented.datetime,
                    slot: suggestedSlotToBePresented,
                  },
                }),
              },
            });
          }
        }
      }
    }
    return suggestedPackageDates;
  } catch (e) {
    console.log("error in getPackageSuggestedTimes", e);
  }
}
export const searchProviderSchedulesByAddress = async (
  coords,
  providerId,
  serviceTypeIds,
  companyId
) => {
  try {
    let result = await API.get("searchapi", "/providers", {
      queryStringParameters: {
        providerId,
        lng: coords.lng,
        lat: coords.lat,
        stids: serviceTypeIds,
        companyId,
      },
    });

    if (result && result.length > 0) {
      return result.map((esr) => {
        return {
          id: esr.id,
          active: esr.active,
          scheduleinfo: esr.scheduleinfo,
        };
      });
    } else return [];
  } catch (ex) {
    console.log("error", ex);
    return [];
  }
};

export const compareTimezones = (tz1, tz2) => {
  const nowDate = new Date();
  console.log(
    utcToZonedTime(nowDate, tz1) > utcToZonedTime(nowDate, tz2),
    utcToZonedTime(nowDate, tz1) < utcToZonedTime(nowDate, tz2)
  );
  return utcToZonedTime(nowDate, tz1) > utcToZonedTime(nowDate, tz2);
};

export function sortProvidersByRating(providers) {
  return providers.sort((p1, p2) => {
    let r1 = p1.ratingstarsavg ? p1.ratingstarsavg : 0;
    let r2 = p2.ratingstarsavg ? p2.ratingstarsavg : 0;
    if (r1 > r2) return -1;
    if (r1 < r2) return 1;
    let n1 = `${p1.firstname}${p1.lastname}`.toUpperCase();
    let n2 = `${p2.firstname}${p2.lastname}`.toUpperCase();
    if (n1 > n2) return 1;
    if (n1 < n2) return -1;
    return 0;
  });
}

function calculateDailyPossibleMaxAvailability(availableIntervals) {
  if (availableIntervals) {
    let counti = 0;
    for (let interval of availableIntervals) {
      counti += interval[1] - interval[0];
    }
    return counti * SLOTUNITMINS;
  }
  return 0;
}
export function calculateDailyStats(unreadables) {
  let dailyStats = [];
  if (unreadables) {
    for (let d of unreadables) {
      if (d.hasavail) {
        let tfd = new Set();
        let slotlengths = d.slotmap.reduce(
          function (sl, s, i) {
            if (s) {
              sl.currLen++;
              sl.total++;
              sl.live = true;
              tfd.add(TIMEMAP[i].tfd);
            } else {
              if (sl.live) {
                if (sl.currLen > sl.currMaxLen) {
                  sl.currMaxLen = sl.currLen;
                }
                if (sl.currMinLen > sl.currLen) {
                  sl.currMinLen = sl.currLen;
                }
                sl.currLen = 0;
                sl.live = false;
              }
            }
            return sl;
          },
          { currLen: 0, currMinLen: 288, currMaxLen: 0, total: 0, live: false }
        );
        const dailyPossibleMaxAvil = calculateDailyPossibleMaxAvailability(
          d.availableIntervals
        );
        dailyStats.push({
          d: moment(d.date).format("YYYY-MM-DD"),
          p:
            dailyPossibleMaxAvil === 0
              ? 0
              : ((slotlengths.total * SLOTUNITMINS) / dailyPossibleMaxAvil) *
                100,
          sxl: slotlengths.currMaxLen * SLOTUNITMINS,
          m: tfd.has(1) ? 1 : 0,
          a: tfd.has(2) ? 1 : 0,
          e: tfd.has(3) ? 1 : 0,
          stl: slotlengths.total * SLOTUNITMINS,
        });
      }
    }
  }
  return dailyStats;
}

export {
  createTimeblock,
  awsDateToJsDate, // turns YYYY-MM-DD formated date into JS date object of browser local TZ
  getAWSDate, //returns a given date in YYYY-MM-DD format
  validateApptDate,
  _getSchedules,
  SCHEDPKSKSPLITAT,
  getSBsByScheduleInfo,
  getCompanyLocation,
  checkSelectedSlotStillAvailable,
  checkSlotStillAvailablePkgBkgFlow,
  calcDist,
  checkPackageBookedSlotsValid,
  getSlotsForSbs,
  calculateBookedMinutes,
  getEndingDate,
  getTimeSuggestions,
  getPackageSuggestedTimes,
  getPhysicalLocationGeoLoc,
};
