export const listAllScheduleItems = /* GraphQL */ `
  query listProviderSchedules(
    $id: ID
    $scheduleinfo: ModelStringKeyConditionInput
    $filter: ModelProviderScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProviderSchedules(
      id: $id
      scheduleinfo: $scheduleinfo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        scheduleinfo
        companyId
        providerId
        active
        startDate
        endDate
        startTime
        endTime
        type
        status
        weeksToRepeat
        weekDays
        locations
        tz
      }
      nextToken
    }
  }
`;

export const listTimeblocks = /* GraphQL */ `
  query listProviderSchedules(
    $id: ID
    $scheduleinfo: ModelStringKeyConditionInput
    $filter: ModelProviderScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProviderSchedules(
      id: $id
      scheduleinfo: $scheduleinfo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        scheduleinfo
        startDate
        endDate
        startTime
        endTime
        type
        status
        weeksToRepeat
        weekDays
        active
        sdtutc
        tz
        latitude
        longitude
      }
      nextToken
    }
  }
`;

export const providerDataForScheduleQuery = /* GraphQL */ `
  query GetProvider($id: ID!) {
    getProvider(id: $id) {
      servicetypes {
        items {
          servicetype {
            id
            name
          }
        }
        nextToken
      }
      locations {
        items {
          id
          name
          maxtraveltype
          traveldistance
          traveldistanceunit
        }
        nextToken
      }
    }
  }
`;
