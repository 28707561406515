import makeStyles from "@mui/styles/makeStyles";
import chroma from "chroma-js";
import { responsiveFontSizes, createTheme } from "@mui/material/styles";
import typography from "../components/UI/theme/typography";
export const useStyles = makeStyles((theme) => ({
  imgStyle: (props) => ({
    background: `${chroma(props.buttonColor).darken().hex()}`,
    color: "#fff",
    height: "35vh",
    overflowX: "hidden",
    overflowY: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "70px 100px",

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0.5,
      zindex: -1,
      backgroundImage: `url("${props.backgroundImageUrl}")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  }),

  bannerLogo: (props) => ({
    width: 80,
    height: 80,
  }),

  root: (props) => ({
    "& .Mui-focused": {
      color: `${props.textColor}`,
    },
    "& .MuiFilledInput-root": {
      border: "2px solid #d3d3d3",
      borderRadius: "3px",
      marginLeft: "-16px",
    },

    "& label.Mui-focused": {
      color: `${props.buttonColor}`,
    },
  }),
  contextbox: {},
  title1: (props) => ({ marginBottom: theme.spacing(2), fontWeight: "bold" }),
  title2: (props) => ({ marginBottom: theme.spacing(1) }),
  title3: (props) => ({ marginBottom: theme.spacing(1) }),

  checkbox: (props) => ({
    paddingTop: "0em",
    textAlign: "center",
    color: `${props.textColor}`,
  }),
  location: (props) => ({
    paddingTop: "1em",
    textAlign: "center",
    color: `${props.textColor}`,
  }),
  notchedOutline: (props) => ({
    borderWidth: "1px",
    borderColor: `${props.textColor} !important`,
  }),
  notchedOutlineProviderDir: (props) => ({
    borderWidth: "1px",
    borderColor: `${props.buttonColor} !important`,
  }),
  outlined: (props) => ({
    color: `${props.textColor}`,
  }),
  inputRoot: (props) => ({
    color: `${props.textColor}`,
  }),
  inputRoot1: (props) => ({
    color: "#000",
  }),
  button: {
    color: "#ffffff",
    backgroundColor: (props) => props.buttonColor,
  },
  buttonProgress: {
    position: "absolute",
    top: "30%",
    marginTop: -1,
    marginLeft: -1,
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
  },
  paperProviderDir: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
    borderStyle: "solid",
    borderColor: (props) => props.buttonColor,
    borderWidth: "thin",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  dropdown: {
    paddingTop: "6px",
    paddingBottom: "2px",
  },
  groupLabel: { fontWeight: "bold", fontStyle: "italic" },
  autocomplete: {
    width: 320,
    [theme.breakpoints.down("sm")]: {
      width: 250,
    },
  },
  autocompleteusers: {
    width: 417,
    paddingLeft: 13,
    [theme.breakpoints.down("sm")]: {
      width: 330,
    },
  },
  qtywidth: {
    width: 40,
    [theme.breakpoints.down("sm")]: {
      width: 28,
    },
  },
}));

export const buildMainTheme = (
  primaryColor,
  primaryButtonTextColor,
  textColor
) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        light: chroma(primaryColor).alpha(0.1).hex(),
        medium: chroma(primaryColor).alpha(0.25).hex(),
      },
      secondary: {
        main: `${chroma(primaryColor).darken().hex()}`,
      },
      info: {
        main: "#A7A7A7",
      },
      warning: {
        main: `${chroma(primaryColor).darken().hex()}`,
      },
      background: {
        main: "#F4F4F4",
      },
      text: {
        main: textColor,
      },
      textFieldPlaceHolder: {
        main: "#D9D9D9",
      },
    },
    typography: typography,
    components: {
      //accordion
      MuiAccordion: {
        styleOverrides: {
          root: {
            padding: "0",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: "16px 12px",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              color: primaryColor,
            },
          },
          content: {
            margin: 0,
            "& .MuiFormControlLabel-root": {
              margin: 0,
            },
            "& .MuiCheckbox-root": {
              padding: 0,
            },
            "& .MuiFormControlLabel-label": {
              padding: "0 11px",
            },
          },
        },
      },
      //alert
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: `${chroma(primaryColor).alpha(0.3).hex()}`,
            alignItems: "center",
          },
          icon: {
            marginRight: "0.625rem",
            color: `${chroma(primaryColor).darken(3).hex()}`,
          },
        },
      },
      //collapse:
      MuiCollapse: {
        styleOverrides: {
          wrapperInner: {
            marginBottom: "0.5rem",
          },
        },
      },
      //tabs
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "uppercase",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "700",
            "&:active": {
              border: "none",
            },
            "&:focus": {
              border: "none",
            },
          },
        },
      },
      //tab panel
      MuiTabPanel: {
        styleOverrides: {
          root: {
            "& .MuiFormControlLabel-root": {
              marginLeft: "0px",
            },
          },
        },
      },
      //google autocomplete
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            width: "100%",
            "& .MuiFormControl-root": {
              width: "100%",
              fontSize: "1rem",
              fontWeight: "400",
              fontFamily: ["Roboto", "sans-serif"].join(","),
            },
          },
        },
      },
      //paper
      MuiPaper: {
        styleOverrides: {
          root: {
            "& .MuiFormControlLabel-root": {
              margin: 0,

              "& .MuiRadio-root": {
                padding: "0px 10px 0px 0px",
              },
            },
          },
        },
      },
      //Button
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: "90px",
            height: "2.5rem",
            padding: "0px 1.25rem",
            boxShadow: "none",
            fontWeight: 700,
            fontSize: "1rem",
            textTransform: "uppercase ",
            "&.MuiButtonGroup-grouped": {
              padding: "0px",
            },
            "&.Mui-disabled": {
              color: "white",
            },
          },
          containedPrimary: {
            color: primaryButtonTextColor,
            "&:hover": {
              boxShadow: "none",
            },
          },
          text: {
            "&:hover": {
              backgroundColor: "transparent",
            },
            alignItems: "center",
            fontWeight: 500,
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontSize: "0.88rem",
            color: "rgba(0,0,0,0.5)",
            textTransform: "capitalize",
            "& .MuiButton-startIcon": {
              margin: 0,
            },
          },
        },
      },
      //Pagination
      MuiPagination: {
        styleOverrides: {
          root: {
            "& .MuiPaginationItem-outline": {
              border: "1px solid #ccc",
              background: "white",
            },

            "& .MuiPaginationItem-previousNext": {
              border: "1px solid #ccc",
              background: `white`,
            },

            "& .Mui-selected": {
              border: `1px solid ${primaryColor}`,
              background: `${chroma(primaryColor).alpha(0.5).hex()}`,
            },

            "& .Mui-disabled": {
              background: `${chroma("#cccccc").alpha(0.6).hex()}`,
            },
          },
        },
      },
      //formcontrol select
      MuiFormControl: {
        styleOverrides: {
          root: {
            "& .MuiFormLabel-filled": {
              color: primaryColor,
            },
            "& .MuiFilledInput-root": {
              background: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              "& .MuiSelect-select": {
                background: "white",
                borderRadius: "4px",
              },
            },
            "& .MuiInputBase-root": {
              background: "white",
            },

            "& .MuiInputBase-input": {
              background: "white",
              borderRadius: "4px",
            },
            "& .MuiInputBase-multiline": {
              background: "white",
              borderRadius: "4px",
            },
            "& .MuiInputBase-inputMultiline": {
              background: "white",
              borderRadius: "4px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#D9D9D9",
              },
              "&:hover fieldset": {
                borderColor: "#D9D9D9",
              },
              "&.Mui-focused fieldset": {
                borderColor: primaryColor,
              },
            },
          },
        },
      },

      //formcontaol label
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: 0,
          },
        },
      },

      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            "& .MuiTypography-caption": {
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: "400",
              color: "rgba(0,0,0,1)",
            },
          },
        },
      },
    },
  });
  return responsiveFontSizes(theme);
};
