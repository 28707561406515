import React, { useEffect, useContext } from "react";
import Container from "@mui/material/Container";
import MainNavBar from "../components/MainNavbar";
import Auth from "@aws-amplify/auth";
import { withRouter } from "react-router-dom";
import { UserContext } from "../App";

function Logout(props) {
  const { user, guestUser } = useContext(UserContext);

  useEffect(() => {
    async function doLogout() {
      await Auth.signOut();
      props.setLoggedOut(true);
      // props.history.push("/");
    }
    doLogout();
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <MainNavBar company={props.company} />
        <br />
        <br />
        <br />
        Signed out
      </Container>
    </>
  );
}
export default withRouter(Logout);
