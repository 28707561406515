// ************************************
// Common billing routines
// ************************************
import { Logger } from "@aws-amplify/core";
import API from "@aws-amplify/api";

async function chargeCreditCard(
  stripecustomerid,
  amount,
  customerCurrency,
  receiptemailaddress,
  credit,
  prevChargeId,
  companyId,
  stripeAccount,
  stripeConnectEnabled
) {
  const logger = new Logger("chargeCreditCard");
  const result = await API.post("stripechargecard", "/stripechargecard", {
    body: {
      amount,
      customerCurrency,
      stripecustomerid,
      receiptemailaddress,
      credit,
      prevcharge: prevChargeId,
      companyId,
      stripeAccount: stripeAccount,
      stripeConnectEnabled: stripeConnectEnabled,
    },
  });

  logger.warn("result from stripechargecard = ");
  logger.warn(result);

  return result;
}
async function checkCardExpiration({
  stripecustomerid,
  companyId,
  clientEmailAddress,
  stripeAccount,
  stripeConnectEnabled,
}) {
  const result = await API.post("stripechargecard", "/checkcardexpiration", {
    body: {
      stripecustomerid,
      clientEmailAddress,
      companyId,
      stripeAccount: stripeAccount,
      stripeConnectEnabled: stripeConnectEnabled,
    },
  });
  return result;
}
export { chargeCreditCard, checkCardExpiration };
