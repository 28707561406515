import { logSystemError } from "../../modules/SystemErrorService";

async function loadData(url, companyId) {
  let response = await fetch(url);
  if (response.ok) {
    let text = await response.text(); // read response body as text
    return text;
  } else {
    await logSystemError(
      companyId,
      "ERROR",
      `status code ${response.status} ${response.statusText} - while loading url ${url}`,
      0,
      {}
    );
    console.log("loadData", response);
    return "Error";
  }
}

/**
 * Function to check if a company has an uploaded Terms of Service file.
 * @param {*} companyId
 * @returns hasTos - boolean
 */
const checkCompanyHasTosFile = async (companyId) => {
  try {
    let hasTos = false;
    const tosPdfUrl = `${process.env.REACT_APP_TOS_DOC_URL}${companyId}-tos.pdf`;
    const pdfExists = await loadData(tosPdfUrl, companyId);

    const tosHtmlUrl = `${process.env.REACT_APP_TOS_DOC_URL}${companyId}-tos.html`;
    const htmlExists = await loadData(tosHtmlUrl, companyId);

    if (pdfExists !== "Error") {
      hasTos = true;
    } else if (htmlExists !== "Error") {
      hasTos = true;
    }

    return hasTos;
  } catch (e) {
    console.log("error loading terms.");
  }
};

export { loadData, checkCompanyHasTosFile };
