import * as queries from "../graphql/queries";

import API, { graphqlOperation } from "@aws-amplify/api";

/**
 * Function to trigger all hooks associated with the id and hookName
 * @param {Object} data - The data to be sent to an endpoint
 * @param {string} id - Used to retrieve integration refData - integration|${id}
 * @param {string} hookName - Used to reference the appropriate hook
 * @returns {bool}
 */
async function TriggerManager(data, id, hookName) {
  console.log("this is the data from TriggerManager", data);
  console.log("this is the hookName", hookName);

  //function to send data to a list of urls
  function invokeTrigger(urlList, data) {
    let urls = urlList;
    for (let i = 0; i < urls.length; i++) {
      window
        .fetch(urls[i], {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
        .then((response) => {
          console.log("Performed hook for url", urls[i]);
        })
        .catch((error) => {
          console.error("Error performing hook for url", urls[i], error);
        });
    }
  }

  try {
    const exists = await API.graphql(
      graphqlOperation(queries.getRefData, {
        refType: `integration|${id}`,
        refName: "data-integration",
      })
    );
    console.log("exists from TriggerManager", exists);

    //check to see if refData exists
    if (exists.data.getRefData) {
      console.log(
        "overrideValue from TriggerManager",
        JSON.parse(exists.data.getRefData.overrideValue)
      );
      let overrideValue = JSON.parse(exists.data.getRefData.overrideValue);

      //check to see if a hook with the hookName exists
      if (overrideValue.hooks && overrideValue.hooks[hookName]) {
        console.log(
          "found hook name!",
          hookName,
          overrideValue.hooks[hookName]
        );
        let hook = overrideValue.hooks[hookName];
        let hookUrls = [];
        for (let i = 0; i < hook.length; i++) {
          hookUrls.push(hook[i].url);
        }
        console.log("hookUrls from TriggerManager", hookUrls);
        invokeTrigger(hookUrls, data);
      } else {
        console.log("unable to find hook", hookName);
        return;
      }
    } else {
      console.log("integration refData does not exist for id", id);
      return;
    }
  } catch (e) {
    console.log("error in getting refdata", e);
  }
}

export { TriggerManager };
