import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../graphql/queries";

const initializeGTM = (gtmId) => {
  const gtmScript = document.createElement("script");
  gtmScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');
  `;
  document.head.appendChild(gtmScript);
};

const fetchGTMIds = async (companyId) => {
  try {
    const result = await API.graphql(
      graphqlOperation(queries.getRefData, {
        refType: `integration|${companyId}`,
        refName: "data-integration",
      })
    );
    let hookIds = null;

    if (result.data.getRefData) {
      let overrideValue = JSON.parse(result.data.getRefData.overrideValue);

      //check to see if a hook with the hookName exists
      if (overrideValue.hooks && overrideValue.hooks["google-tag-manager"]) {
        let hooks = overrideValue.hooks["google-tag-manager"];
        hookIds = hooks.map((hook) => hook.id);
      }
    }
    return hookIds;
  } catch (e) {
    console.log("Error: unable to fetchGTMId for companyId ", companyId);
  }
};

export { initializeGTM, fetchGTMIds };
