import API, { graphqlOperation } from "@aws-amplify/api";

async function execWrite(query) {
  try {
    const { opname, op, input } = query;
    const resp = await API.graphql(graphqlOperation(op, { input: input }));
    if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
      return {
        ...resp.data[opname],
      };
    } else {
      return {
        error: "something went wrong please try again later",
      };
    }
  } catch (error) {
    return {
      error,
    };
  }
}

async function execRead(query) {
  try {
    const { opname, op, filter, limit } = query;
    let resp = null;
    let nextToken = null;
    const items = [];
    do {
      resp = await API.graphql(
        graphqlOperation(op, {
          filter,
          ...(limit ? { limit } : { limit: 10 }),
          nextToken,
        })
      );
      if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
        //TODO: check if hasOwnProperty should be used or [opname]
        items.push(...resp.data[opname].items);
        nextToken = resp.data[opname].nextToken;
      } else {
        return {
          error: "something went wrong please try again later",
        };
      }
    } while (nextToken);
    return {
      items,
    };
  } catch (error) {
    return {
      error,
    };
  }
}

async function execReadBySortkey(query) {
  try {
    const { opname, op, id, skey, filter, limit, sortDirection } = query;
    let resp = null;
    let nextToken = null;
    const items = [];
    do {
      // console.log("nexttoken:" + nextToken);
      resp = await API.graphql(
        graphqlOperation(op, {
          ...id,
          ...skey,
          filter,
          ...(limit ? { limit } : { limit: 10 }),
          ...(sortDirection ? { sortDirection } : { sortDirection: "ASC" }),
          nextToken,
        })
      );
      // console.log(resp);
      if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
        //TODO: check if hasOwnProperty should be used or [opname]
        items.push(...resp.data[opname].items);
        nextToken = resp.data[opname].nextToken;
      } else {
        return {
          error: "something went wrong please try again later",
        };
      }
    } while (nextToken);
    return {
      items,
    };
  } catch (error) {
    return {
      error,
    };
  }
}

async function execReadByPK(query) {
  try {
    const { opname, op, id, filter, limit, sortDirection } = query;
    let resp = null;
    let nextToken = null;
    const items = [];
    do {
      console.log("nexttoken:" + nextToken);
      resp = await API.graphql(
        graphqlOperation(op, {
          ...id,
          filter,
          ...(limit ? { limit } : { limit: process.env.REACT_APP_LISTLIMIT }),
          ...(sortDirection ? { sortDirection } : { sortDirection: "ASC" }),
          nextToken,
        })
      );
      if (resp && resp.data && resp.data.hasOwnProperty(opname)) {
        //TODO: check if hasOwnProperty should be used or [opname]
        items.push(...resp.data[opname].items);
        nextToken = resp.data[opname].nextToken;
      } else {
        return {
          error: "something went wrong please try again later",
        };
      }
    } while (nextToken);
    return {
      items,
    };
  } catch (error) {
    return {
      error,
    };
  }
}

export { execRead, execWrite, execReadBySortkey, execReadByPK };
