import WarningTriangleIcon from "../images/WarningTriangleIcon.svg";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";

const MaintenanceDialog = () => {
  return (
    <>
      <Dialog open={true} headingIcon={WarningTriangleIcon}>
        <DialogTitle
          sx={{
            fontSize: "1.5rem",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <img
            src={WarningTriangleIcon}
            alt="Warning Triangle Icon"
            style={{ height: "24px", width: "24px" }}
          />
          Ongoing maintenance
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "18px" }}
        >
          <Box>SCHEDULED MAINTENANCE IN PROGRESS</Box>
          <Box>
            Our system will be temporarily unavailable on Tuesday, September 24,
            while we perform planned system maintenance
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MaintenanceDialog;
