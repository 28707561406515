import { loadData } from "../Common/Template";
export const guestCheckoutEmail = async (props) => {
  console.log(props);
  const {
    client_firstname,
    client_lastname,
    client_email,
    companyId,
    company,
  } = props;

  let htmlTemplate = await loadData(
    `https://do1e1nd2rp5b.cloudfront.net/public/email-templates/${company.id}/guest-checkout.html`,
    companyId
  );

  // check if we got a template, if not load a generic template
  if (htmlTemplate === "Error") {
    // load generic template
    htmlTemplate = await loadData(
      "https://do1e1nd2rp5b.cloudfront.net/public/email-templates/modified-templates/guest-checkout.html",
      companyId
    );
  }

  // if still an error, we have a network problem
  if (htmlTemplate === "Error") {
    htmlTemplate =
      "We are unable to load your order receipt template. Please check your network connection.";
  }

  htmlTemplate = htmlTemplate.replace(
    /\[company-auth-site-url\]/g,
    process.env.REACT_APP_AUTH_SITE_URL
  );
  htmlTemplate = htmlTemplate.replace(/\[company-logo-url\]/g, company.logoUrl);
  htmlTemplate = htmlTemplate.replace(
    /\[company-color\]/g,
    company.primaryColor
  );

  //  company values
  htmlTemplate = htmlTemplate.replace(/\[company-name\]/g, company.name);
  htmlTemplate = htmlTemplate.replace(
    /\[company_email\]/g,
    company.emailaddress
  );
  htmlTemplate = htmlTemplate.replace(
    /\[client_firstname\]/g,
    client_firstname
  );
  htmlTemplate = htmlTemplate.replace(/\[client_lastname\]/g, client_lastname);

  htmlTemplate = htmlTemplate.replace(/\[client_email\]/g, client_email);
  if (company.subdomain) {
    const signupUrl = `https://${company.subdomain}/signup`;
    htmlTemplate = htmlTemplate.replace(/\[company-booking-url\]/g, signupUrl);
  }

  return htmlTemplate;
};
