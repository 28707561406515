import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainNavBar from "../components/MainNavbar";
import { withRouter } from "react-router-dom";
import Auth from "@aws-amplify/auth";
const text_field_style = { width: "100%", maxWidth: "300px" };

function Forgot(props) {
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [pasw, setPasw] = useState("");
  const [errorMsg, setErrorMsg] = useState();
  const [error, setError] = useState();
  const smallerScreen = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (
      props.location.state &&
      props.location.state.stage &&
      props.location.state.email
    ) {
      setStage(props.location.state.stage);
      setEmail(props.location.state.email);
    }
  });

  async function sendCode() {
    if (email) {
      try {
        await forgotPassword();
      } catch (err) {
        console.log("error whole forgotpassword");
        console.log(err);
        setErrorMsg(err.message);
      }
    } else {
      setErrorMsg("Please enter a valid email address");
    }
  }
  const forgotPassword = async () => {
    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
        setStage(2);
      })
      .catch((err) => setErrorMsg(err.message));
  };

  const resetPassword = async () => {
    Auth.forgotPasswordSubmit(email, confirmationCode, pasw)
      .then((data) => {
        console.log("resetPassword response", data);
        props.history.push("/login", {
          fromPath: props.location.state.fromPath,
        });
      })
      .catch((err) => {
        console.log("resetPassword error", err);
      });
  };

  return (
    <div>
      <MainNavBar
        company={props.company}
        user={null}
        guestUser={true}
        signOutOp={props.signOutOp}
        currentPath={props.location.pathname}
        banner={false}
        publicsitesettings={props.publicsitesettings}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "115px",
          padding: "0 1rem",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          {`Reset your password`}
        </Typography>
        {stage === 1 && (
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {`Please enter your email address to receive the confirmation code.`}
          </Typography>
        )}
        {stage === 2 && (
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {`Please enter your confirmation code and new password.`}
          </Typography>
        )}
        {errorMsg && (
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            <Typography variant="body2" style={{ color: "red" }}>
              {errorMsg}
            </Typography>
          </Box>
        )}
        {stage === 2 ? (
          <>
            <TextField
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              size="small"
              id="userenteredinfo"
              label="Confirmation code"
              value={confirmationCode ? confirmationCode : ""}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                gap: "1rem",
                width: "100%",
              }}
            >
              <TextField
                sx={{
                  ...text_field_style,
                  alignSelf: "center",
                  maxWidth: !smallerScreen ? "unset" : "300px",
                }}
                type="password"
                InputProps={{ disableUnderline: true }}
                variant="outlined"
                size="small"
                id="password"
                label="Password"
                value={pasw}
                onChange={(e) => setPasw(e.target.value)}
              />
            </Box>

            <Button
              variant="contained"
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              onClick={resetPassword}
              disabled={
                confirmationCode.trim().length === 0 || pasw.trim().length === 0
              }
            >
              Reset password
            </Button>
          </>
        ) : (
          <>
            <TextField
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              size="small"
              id="email"
              label="Email"
              value={email}
              onChange={(e) => {
                let ev = e.target.value;
                ev = ev.trim().toLowerCase();
                setEmail(ev);
                if (errorMsg) setErrorMsg();
              }}
            />
            <Button
              variant="contained"
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              onClick={sendCode}
              disabled={email.trim().length === 0}
            >
              Send code
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(Forgot);
