import mixpanel from "mixpanel-browser";
import { getUserFromCache } from "../../user/UserCommon";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: true,
  ignore_dnt: true,
}); // dev
// mixpanel.track("Tracking after mixpanel init", {
//   debug: true,
//   ignore_dnt: true,
// });

async function trackUser() {
  const loggedInUser = await getUserFromCache();
  if (loggedInUser) {
    mixpanel.people.set({
      emailaddress: loggedInUser.emailaddress,
      name: loggedInUser.firstname + " " + loggedInUser.lastname,
    });
    if (loggedInUser.emailaddress) {
      mixpanel.identify(loggedInUser.emailaddress);
    } else {
      mixpanel.identify("guest@gomarketbox.com"); // guest
    }
  } else {
    mixpanel.people.set({ emailaddress: "", name: "Guest User" });
    mixpanel.identify("guest@gomarketbox.com"); // guest
  }
}

// tracks a user selection
function trackServiceSelection(eventProps) {
  mixpanel.track("Service Selection", eventProps);
  trackUser();
}

// tracks a provider searcg
function trackProviderSearch(eventProps) {
  mixpanel.track("Provider Search", eventProps);
  trackUser();
}

// tracks a provider selection
function trackProviderSelection(eventProps) {
  mixpanel.track("Provider Selection", eventProps);
  trackUser();
}

// tracks a package selection
function trackPackageSelection(eventProps) {
  mixpanel.track("Package Selection", eventProps);
  trackUser();
}

// tracks a timeslot selection
function trackTimeslotSelection(eventProps) {
  mixpanel.track("Timeslot Selection", eventProps);
  trackUser();
}

// tracks a booking confirmation
function trackBookingConfirmation(eventProps) {
  mixpanel.track("Booking Confirmed", eventProps);
  trackUser();
}

// tracks order summary viewed
function trackOrderSummaryViewed(eventProps) {
  mixpanel.track("Order Summary Viewed", eventProps);
  trackUser();
}

// tracks Login
function trackLogin(eventProps) {
  mixpanel.track("Login on Public Site", eventProps);
  trackUser();
}

// tracks registration
function trackRegistration(eventProps) {
  mixpanel.track("Registration on Public Site", eventProps);
  trackUser();
}

// tracks payment processed
function trackPaymentProcessed(eventProps) {
  mixpanel.track("Payment Processed", eventProps);
  trackUser();
}

// tracks order completed
function trackOrderCompleted(eventProps) {
  mixpanel.track("Order Completed", eventProps);
  trackUser();
}

export {
  trackServiceSelection,
  trackProviderSearch,
  trackProviderSelection,
  trackPackageSelection,
  trackTimeslotSelection,
  trackBookingConfirmation,
  trackOrderSummaryViewed,
  trackLogin,
  trackRegistration,
  trackPaymentProcessed,
  trackOrderCompleted,
};
