import { types } from "./reducers";

export const useActions = (state, dispatch) => {
  // this stores the booking state selected in the booking flow
  function setBookingState(bookingState) {
    dispatch({ type: types.SET_BOOKING_STATE, payload: bookingState });
    let stringifyBookingState = JSON.stringify(bookingState);
    console.log("****Booking State Context****");
    console.log(JSON.parse(stringifyBookingState));
    //console.log("bookingState = " + JSON.stringify(bookingState));
  }

  return {
    setBookingState,
  };
};
