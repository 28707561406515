const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const initialState = {
  bookingState: {
    company: {},
    companyId: "",
    serviceType: "",
    provider: "",
    address: "",
    location: "",
  },
  BROWSER_TZ,
};

const types = {
  SET_BOOKING_STATE: "SET_BOOKING_STATE",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BOOKING_STATE:
      return {
        ...state,
        bookingState: action.payload,
      };
    default:
      throw new Error("Unexpected action");
  }
};
export { initialState, types, reducer };
