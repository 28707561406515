import * as track from "../utils/Common/Mixpanel";

export function trackProviderSearch({
  searchParams,
  selectedFromDate,
  selectedToDate,
  selectedTod,
  selectedSkillsNames,
  companyId,
  serviceType1,
  serviceType2,
  serviceType3,
}) {
  if (searchParams) {
    try {
      let eventprops = {
        searchParams,
        selectedFromDate,
        selectedToDate,
        selectedTod,
        selectedSkillsNames,
        companyId,
        serviceType1,
        serviceType2,
        serviceType3,
      };
      track.trackProviderSearch(eventprops);
    } catch (e) {
      console.log(e);
    }
  }
}

export function trackProviderSelection(bookingState, selectedSkillsNames) {
  if (bookingState) {
    try {
      let {
        provider,
        datefilter,
        companyId,
        isRemoteLocation,
        location,
        serviceType,
        isVirtual,
        appointmentLocation,
      } = bookingState;
      let eventprops = {
        ...datefilter,
        provider,
        selectedSkillsNames,
        companyId,
        isRemoteLocation,
        location,
        serviceType,
        isVirtual,
        appointmentLocation,
      };
      track.trackProviderSelection(eventprops);
    } catch (e) {
      console.log(e);
    }
  }
}

export function trackPackageSelection(bookingState) {
  try {
    let {
      boughtPackage,
      clientPackage,
      sdt,
      pkgsdt,
      companyId,
      provider,
      datefilter,
      isRemoteLocation,
      location,
      serviceType,
      isVirtual,
      appointmentLocation,
    } = bookingState;
    track.trackPackageSelection({
      boughtPackage,
      clientPackage,
      sdt,
      pkgsdt,
      companyId,
      provider,
      datefilter,
      isRemoteLocation,
      location,
      serviceType,
      isVirtual,
      appointmentLocation,
    });
  } catch (e) {
    console.log(e);
  }
}

export function trackTimeslotSelection(bookingState) {
  try {
    let {
      boughtPackage,
      clientPackage,
      sdt,
      pkgsdt,
      companyId,
      provider,
      datefilter,
      isRemoteLocation,
      location,
      serviceType,
      isVirtual,
      appointmentLocation,
      repeatingAppointment,
      selectedslot,
    } = bookingState;
    track.trackTimeslotSelection({
      boughtPackage,
      clientPackage,
      sdt,
      pkgsdt,
      companyId,
      provider,
      datefilter,
      isRemoteLocation,
      location,
      serviceType,
      isVirtual,
      appointmentLocation,
      repeatingAppointment,
      selectedslot,
    });
  } catch (e) {
    console.log(e);
  }
}

export function trackBookingConfirmation(bookingState) {
  try {
    let {
      boughtPackage,
      clientPackage,
      sdt,
      pkgsdt,
      companyId,
      provider,
      datefilter,
      isRemoteLocation,
      location,
      serviceType,
      isVirtual,
      appointmentLocation,
      repeatingAppointment,
      selectedslot,
      tryOtherProviders,
    } = bookingState;
    track.trackBookingConfirmation({
      boughtPackage,
      clientPackage,
      sdt,
      pkgsdt,
      companyId,
      provider,
      datefilter,
      isRemoteLocation,
      location,
      serviceType,
      isVirtual,
      appointmentLocation,
      repeatingAppointment,
      selectedslot,
      tryOtherProviders,
    });
  } catch (e) {
    console.log(e);
  }
}

export function trackOrderCompleted(bookingState, order) {
  if (bookingState && order) {
    try {
      let {
        boughtPackage,
        clientPackage,
        sdt,
        pkgsdt,
        companyId,
        provider,
        datefilter,
        isRemoteLocation,
        location,
        serviceType,
        isVirtual,
        appointmentLocation,
        repeatingAppointment,
        selectedslot,
        tryOtherProviders,
      } = bookingState;
      track.trackOrderCompleted({
        boughtPackage,
        clientPackage,
        sdt,
        pkgsdt,
        companyId,
        provider,
        datefilter,
        isRemoteLocation,
        location,
        serviceType,
        isVirtual,
        appointmentLocation,
        repeatingAppointment,
        selectedslot,
        tryOtherProviders,
        order,
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export function trackOrderSummaryViewed(bookingState) {
  if (bookingState) {
    try {
      let {
        boughtPackage,
        clientPackage,
        sdt,
        pkgsdt,
        companyId,
        provider,
        datefilter,
        isRemoteLocation,
        location,
        serviceType,
        isVirtual,
        appointmentLocation,
        repeatingAppointment,
        selectedslot,
        tryOtherProviders,
      } = bookingState;
      track.trackOrderSummaryViewed({
        boughtPackage,
        clientPackage,
        sdt,
        pkgsdt,
        companyId,
        provider,
        datefilter,
        isRemoteLocation,
        location,
        serviceType,
        isVirtual,
        appointmentLocation,
        repeatingAppointment,
        selectedslot,
        tryOtherProviders,
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export function trackRegistration(newUser) {
  if (newUser) {
    try {
      let {
        id,
        username,
        emailaddress,
        registered,
        firstname,
        lastname,
        phonepref,
        city,
        state,
        country,
        postalcode,
        role,
        companyId,
      } = newUser;
      track.trackRegistration({
        id,
        username,
        emailaddress,
        registered,
        firstname,
        lastname,
        phonepref,
        city,
        state,
        country,
        postalcode,
        role,
        companyId,
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export function trackLogin(user) {
  if (user) {
    try {
      let {
        id,
        username,
        emailaddress,
        registered,
        firstname,
        lastname,
        phonepref,
        city,
        state,
        country,
        postalcode,
        role,
        companyId,
      } = user;
      track.trackLogin({
        id,
        username,
        emailaddress,
        registered,
        firstname,
        lastname,
        phonepref,
        city,
        state,
        country,
        postalcode,
        role,
        companyId,
      });
    } catch (e) {
      console.log(e);
    }
  }
}
export function trackPaymentProcessed(bookingState, chargeInfo) {
  if (bookingState) {
    try {
      let useDifferentCard = chargeInfo && chargeInfo.useDifferentCard;
      let amount = chargeInfo && chargeInfo.charge.amount;
      let brand = chargeInfo && chargeInfo.charge.source.brand;
      let currency = chargeInfo && chargeInfo.charge.source.currency;
      let trackCharge = {
        useDifferentCard,
        amount,
        brand,
        currency,
      };
      console.log("trackCharge", trackCharge);
      track.trackPaymentProcessed(trackCharge);
    } catch (e) {
      console.log(e);
    }
  }
}

/*
trackProviderSearch
trackProviderSelection
trackPackageSelection
trackTimeslotSelection
trackBookingConfirmation
trackOrderSummaryViewed
trackLogin
trackRegistration
trackPaymentProcessed
trackOrderCompleted
*/
