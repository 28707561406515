export const ACCOUNT_OR_GUEST = "ACCOUNT_OR_GUEST";
export const MUST_CREATE_ACCOUNT = "MUST_CREATE_ACCOUNT";
export const GUEST_ONLY = "GUEST_ONLY";

export function getCheckoutOption(publicsitesettings) {
  if (publicsitesettings) {
    const co =
      publicsitesettings?.StyleCustomization?.bookingFlowCustomizations
        ?.checkoutOption;
    if (co && co !== null) return co;
  }

  return ACCOUNT_OR_GUEST; //default
}

export function isGuestCheckoutAllowed(publicsitesettings) {
  console.log(
    "isGuestCheckoutAlloweds",
    publicsitesettings?.StyleCustomization?.bookingFlowCustomizations
      ?.checkoutOption
  );
  if (publicsitesettings) {
    const co =
      publicsitesettings?.StyleCustomization?.bookingFlowCustomizations
        ?.checkoutOption;
    if (!co) return true; // when the checkout setting is not defined, allow guest checkout like ACCOUNT_OR_GUEST
    if (ACCOUNT_OR_GUEST === co || GUEST_ONLY === co) return true;
  }
  return false;
}

export function isGuestOnlyCheckout(publicsitesettings) {
  if (publicsitesettings) {
    const co =
      publicsitesettings?.StyleCustomization?.bookingFlowCustomizations
        ?.checkoutOption;
    if (GUEST_ONLY === co) return true;
  }
  return false;
}
